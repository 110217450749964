import React from "react";

const Button = ({ type, cls, text, onClickFn }) => {
  return (
    <button
      type={type}
      className={[
        "font-bold text-white rounded-lg bg-pointColor btnAnimation",
        cls,
      ].join(" ")}
      onClick={onClickFn}
    >
      {text}
    </button>
  );
};

export default Button;
