import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import API from "utils/api";

export const ProtectedRoute = ({ children }) => {
  const [cookies] = useCookies(["token"]);
  const [isValid, setIsValid] = useState(null); // null: 초기 상태

  useEffect(() => {
    const validateToken = async () => {
      if (!cookies.token) {
        setIsValid(false); // 토큰이 없으면 유효하지 않은 상태로 설정
        return;
      }

      try {
        const response = await API.admin_validateToken(cookies.token); // 토큰 유효성 검사
        setIsValid(response.data.valid); // 서버 응답에 따라 상태 설정
      } catch (error) {
        setIsValid(false); // 오류 발생 시 유효하지 않은 상태로 설정
      }
    };

    validateToken();
  }, [cookies.token]);

  // 로딩 상태 처리
  if (isValid === null) {
    return <div>Loading...</div>;
  }

  // 토큰이 없거나 유효하지 않은 경우
  if (!isValid) {
    return <Navigate to="/admin" replace />;
  }

  // 토큰이 유효한 경우
  return children;
};
