import Button from "components/common/Button";
import InputWrap from "components/common/InputWrap";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import API from "utils/api";

const COOKIE_CONFIG = {
  path: "/",
  maxAge: 2 * 60 * 60, // 2 hours
};

const ERROR_MESSAGES = {
  401: `admin.login.form.error.invalid`,
  400: `admin.login.form.error.notFound`,
};

const Admin = () => {
  const { t } = useTranslation();
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [, setCookie] = useCookies(["token"]);
  const navigate = useNavigate();

  const handleAdminLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await API.admin_login({ id, password });
      setCookie("token", response.data.token, COOKIE_CONFIG);
      navigate("/admin/dashboard"); // Redirect to dashboard on successful login
    } catch (error) {
      const status = error.response?.status;
      const errorMessage = ERROR_MESSAGES[status];

      if (errorMessage) {
        alert(t(errorMessage));
      } else {
        alert(t(`admin.login.form.error.unKnown`));
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full p-10 py-20 space-y-10">
      <div className="space-y-4 text-center">
        <div className="text-3xl font-bold">{t(`admin.login.headerText1`)}</div>
        <div>{t(`admin.login.headerText2`)}</div>
      </div>
      <form
        onSubmit={handleAdminLogin}
        className="flex flex-col items-center space-y-4"
      >
        <InputWrap
          inputText={t(`admin.login.form.id`)}
          inputcls={"w-80 sm:w-96"}
          type="text"
          placeholder={t(`admin.login.form.idPlaceholder`)}
          onChangeFn={(e) => setId(e.target.value)}
        />
        <InputWrap
          inputText={t(`admin.login.form.password`)}
          inputcls={"w-80 sm:w-96 placeholder:font-nanum font-serif"}
          type="password"
          placeholder={t(`admin.login.form.passwordPlaceholder`)}
          onChangeFn={(e) => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          text={t(`admin.login.form.loginBtn`)}
          cls={"p-2 px-8"}
        />
      </form>
    </div>
  );
};

export default Admin;
