import { configureStore } from "@reduxjs/toolkit";
import count from "./count";
import mainSolutionCardClickSlice from "./mainSolutionCardClickSlice";
import newslistPageNumSlice from "./newslistPageNumSlice";
import loadingSlice from "./loadingSlice";
import adminNewsModeSlice from "./adminNewsMode";

const store = configureStore({
  reducer: {
    countNum: count.reducer,
    mainSolutionCardClick: mainSolutionCardClickSlice.reducer,
    newslistPageNum: newslistPageNumSlice.reducer,
    loading: loadingSlice.reducer,
    adminNewsMode: adminNewsModeSlice.reducer,
  },
  // 이미지를 받을 수 있게 하는 부분
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
