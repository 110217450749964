import React from "react";
import { toString } from "utils/dateToString";

const InputWrap = ({
  name,
  inputText,
  inputcls,
  type,
  placeholder,
  defaultValue,
  onChangeFn,
}) => {
  return (
    <div className="space-y-2">
      <div className="font-bold">{inputText}</div>
      <input
        className={["p-1 px-2 border rounded-md cursor-pointer", inputcls].join(
          " "
        )}
        type={type}
        name={name}
        defaultValue={type === "date" ? toString(defaultValue) : defaultValue}
        placeholder={placeholder}
        onChange={onChangeFn}
        required
      />
    </div>
  );
};

export default InputWrap;
