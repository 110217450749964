import "./Header.css";
import { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import i18next from "config/lang/i18n";

import { NewslistPageNum } from "store/newslistPageNumSlice";

import headerLogo from "assets/images/logos/MaiT_white.png";
import nav_icon from "assets/images/icons/nav.png";
import close_icon from "assets/images/icons/close.png";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isIncludeAdmin = pathname.includes("admin");

  const [koClick, setKoClick] = useState(true);
  const [enClick, setEnClick] = useState(false);
  const changeLanguage = (lang) => {
    i18next.changeLanguage(lang);
    if (lang === "ko") {
      setKoClick(true);
      setEnClick(false);
    } else {
      setKoClick(false);
      setEnClick(true);
    }
  };

  const navClick = (e) => {
    if (e.target.classList[1] === "nav0") {
      navigate("/");
      dispatch(NewslistPageNum({ text: "zero" }));
      setMoblieNavClick(false);
      window.scrollTo({ top: 0 });
    }
    if (e.target.classList[1] === "nav1") {
      navigate("/intro");
      setMoblieNavClick(false);
      window.scrollTo({ top: 0 });
    }
    if (e.target.classList[1] === "nav2") {
      navigate("/solution");
      setMoblieNavClick(false);
      window.scrollTo({ top: 0 });
    }
    if (e.target.classList[1] === "nav3") {
      navigate("/");
      setMoblieNavClick(false);
      window.scrollTo({ top: 0 });
    }
    if (e.target.classList[1] === "nav4") {
      navigate("/customer");
      setMoblieNavClick(false);
      window.scrollTo({ top: 0 });
    }
    if (e.target.classList[1] === "nav5") {
      navigate("/news");
      setMoblieNavClick(false);
      window.scrollTo({ top: 0 });
    }
    if (e.target.classList[1] === "nav6") {
      navigate("/recruitment");
      setMoblieNavClick(false);
      window.scrollTo({ top: 0 });
    }
  };

  const [moblieNavClick, setMoblieNavClick] = useState(false);

  const handleResize = () => {
    if (window.innerWidth > 800) {
      setMoblieNavClick(false);
    }
  };

  const [scrollY, setScrollY] = useState(0);
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="headerBG">
      <div className="headerWrap">
        <div className="headerLogo">
          <img
            className="headerLogoImg nav0"
            onClick={navClick}
            src={headerLogo}
            alt="MaiT"
          />
        </div>
        <div className="headerButtons">
          <div className="headerNavWrap">
            {isIncludeAdmin ? null : (
              <>
                <div className="header_nav_menu nav1" onClick={navClick}>
                  {t(`header.introduction`)}
                </div>
                <div className="header_nav_menu nav2" onClick={navClick}>
                  {t(`header.solution`)}
                </div>
                <div className="header_nav_menu nav4" onClick={navClick}>
                  {t(`header.customersupport`)}
                </div>
                <div className="header_nav_menu nav5" onClick={navClick}>
                  {t(`header.news`)}
                </div>
                <div className="header_nav_menu nav6" onClick={navClick}>
                  {t(`header.recruitment`)}
                </div>
              </>
            )}
          </div>
          <div className="headerLanBtn">
            <div
              onClick={() => changeLanguage("ko")}
              style={koClick ? { color: "white" } : { color: "#AAAAAA" }}
            >
              KOR
            </div>
            <div
              className="headerLanBtnLine"
              style={{ cursor: "default" }}
            ></div>
            <div
              onClick={() => changeLanguage("en")}
              style={enClick ? { color: "white" } : { color: "#AAAAAA" }}
            >
              ENG
            </div>
          </div>
        </div>
        <div className="headerNavIcon">
          <img
            src={nav_icon}
            alt="nav icon"
            onClick={() => setMoblieNavClick(!moblieNavClick)}
          />
        </div>
      </div>
      <div
        className="mobileNavBG"
        style={moblieNavClick ? { display: "block" } : { display: "none" }}
        onClick={() => setMoblieNavClick(!moblieNavClick)}
      ></div>
      <div
        className="mobileNav"
        style={
          moblieNavClick
            ? { width: "279px", opacity: "1" }
            : { width: "0", opacity: "0" }
        }
      >
        <div className="mobileNavTop">
          <div className="mobileNavLogos">
            <div className="mobileNavLogo">
              <img src={headerLogo} alt="logo" />
            </div>
            <div
              className="mobileNavClose"
              onClick={() => setMoblieNavClick(!moblieNavClick)}
            >
              <img src={close_icon} alt="close icon" />
            </div>
          </div>
          <div className="mobileNavDiv">
            <div className="mobileNavDivCircle"></div>
            <div className="mobileNavDivText nav1" onClick={navClick}>
              {t(`header.introduction`)}
            </div>
          </div>
          <div className="mobileNavDiv">
            <div className="mobileNavDivCircle"></div>
            <div className="mobileNavDivText nav2" onClick={navClick}>
              {t(`header.solution`)}
            </div>
          </div>
          {/* <div className="mobileNavDiv">
                        <div className="mobileNavDivCircle"></div>
                        <div
                            className="mobileNavDivText nav3"
                            onClick={navClick}
                        >
                            {t(`header.papers`)}
                        </div>
                    </div> */}
          <div className="mobileNavDiv">
            <div className="mobileNavDivCircle"></div>
            <div className="mobileNavDivText nav4" onClick={navClick}>
              {t(`header.customersupport`)}
            </div>
          </div>
          <div className="mobileNavDiv">
            <div className="mobileNavDivCircle"></div>
            <div className="mobileNavDivText nav5" onClick={navClick}>
              {t(`header.news`)}
            </div>
          </div>
          <div className="mobileNavDiv">
            <div className="mobileNavDivCircle"></div>
            <div className="mobileNavDivText nav6" onClick={navClick}>
              {t(`header.recruitment`)}
            </div>
          </div>
        </div>
        <div className="mobileNavBottom">
          <div
            onClick={() => changeLanguage("ko")}
            style={koClick ? { color: "white" } : { color: "#AAAAAA" }}
          >
            KOR
          </div>
          <div
            onClick={() => changeLanguage("en")}
            style={enClick ? { color: "white" } : { color: "#AAAAAA" }}
          >
            ENG
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
