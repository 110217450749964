import { createSlice } from "@reduxjs/toolkit";

export const AdminNewsMode = {
  CREATE: "create",
  DELETE: "delete",
  UPDATE: "update",
};

// Redux Slice
const adminNewsModeSlice = createSlice({
  name: "adminNewsModeSlice",
  initialState: {
    mode: null, 
    newsId: null, 
    news: null, 
    refresh:false,
  },
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload; 
    },
    setNewsId: (state, action) => {
      state.newsId = action.payload; 
    },
    setNews: (state, action) => {
      state.news = action.payload;
    },
    resetMode: (state) => {
      state.mode = null; 
      state.newsId = null; 
      state.news = null;
    },
    setRefresh: (state) => {
      state.refresh = !state.refresh;
    },
  },
});
export default adminNewsModeSlice;
export const { setMode, setNewsId, setNews, resetMode, setRefresh } =
  adminNewsModeSlice.actions;
