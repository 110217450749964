import React from "react";
import { useDispatch } from "react-redux";
import { resetMode } from "store/adminNewsMode";

const Modal = ({ element }) => {
  const dispatch = useDispatch();
  const onClickClose = () => {
    dispatch(resetMode());
  };

  return (
    <div className="fixed top-0 bottom-0 flex items-center justify-center w-screen h-screen bg-black bg-opacity-40">
      <div className="p-3 pb-4 bg-white rounded-md">
        <div className="flex justify-end">
          <div
            onClick={onClickClose}
            className="flex items-center justify-center w-6 h-6 transition-all rounded-full cursor-pointer hover:bg-gray-200 btnAnimation "
          >
            x
          </div>
        </div>
        <div className="px-10 ">{element}</div>
      </div>
    </div>
  );
};

export default Modal;
